<mat-card class="user-detail-header">
  <button class="menu-button-header" mat-icon-button [matMenuTriggerFor]="userMenu">
    <mat-icon>more_vert</mat-icon>
  </button>

  <mat-menu #userMenu="matMenu">
    <button mat-menu-item (click)="editUserDetail()">Edit</button>
    <button mat-menu-item (click)="deleteUser()">Delete User</button>
  </mat-menu>

  <div class="user-detail-container">
    <img class="profile-image" src="./../../assets/image/profile.png" />
    <div class="user-details">
      <h2>{{ user.firstName }} {{ user.lastName }}</h2>
      <a href="mailto:{{ user.email }}">{{ user.email }}</a>
    </div>
  </div>
</mat-card>

<mat-card class="mt-32 user-detail-data">
  <button class="menu-button" mat-icon-button [matMenuTriggerFor]="userAddress">
    <mat-icon>more_vert</mat-icon>
  </button>

  <mat-menu #userAddress="matMenu">
    <button mat-menu-item (click)="editMenu()">Edit</button>
  </mat-menu>

  <mat-card-title>Address</mat-card-title>
  <mat-card-content>
    {{ user.street }} <br />
    {{ user.zipCode }} {{ user.city }}
  </mat-card-content>
</mat-card>
