<div class="dialog-container">
    @if(loading) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }
  
  <h2 mat-dialog-title>Edit User</h2>
  <mat-dialog-content class="pt-20">
    <div>
        <mat-form-field class="mr-16" appearance="outline">
            <mat-label>First Name</mat-label>
            <input [disabled]="loading" [(ngModel)]="user.firstName" matInput placeholder="First Name" >
            <!-- <mat-hint>Please enter a Name</mat-hint> -->
          </mat-form-field>
    
          <mat-form-field appearance="outline">
            <mat-label>Last Name</mat-label>
            <input [disabled]="loading" [(ngModel)]="user.lastName" matInput placeholder="Last Name" >
            <!-- <mat-hint>Please enter a Name</mat-hint> -->
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="mr-16 full-width" appearance="outline">
            <mat-label>E-Mail</mat-label>
            <input [disabled]="loading" [(ngModel)]="user.email" matInput placeholder="E-Mail">
            <!-- <mat-hint>Please enter a E-mail</mat-hint> -->
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="mr-16 full-width" appearance="outline">
            <mat-label>Birth Date</mat-label>
            <input [disabled]="loading" [(ngModel)]="birthDate" matInput placeholder="Birthdate" [matDatepicker]="datepicker" >
            <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
            <mat-datepicker #datepicker></mat-datepicker>
            <!-- <mat-hint>Please enter a Date</mat-hint> -->
          </mat-form-field>
        </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button [disabled]="loading" mat-button (click)="dialogRef.close();">Cancel</button>
    <button [disabled]="loading" mat-button (click)="saveUser()" cdkFocusInitial>Save</button>
  </mat-dialog-actions>
  </div>