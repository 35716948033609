<mat-card appearance="outlined" class="signup-container">
    <h2>Sign Up</h2>
    <form #signupForm="ngForm" (ngSubmit)="signup(signupForm)">
      <div class="input-fields-one-line">
        <mat-form-field appearance="outline">
          <mat-label>First Name</mat-label>
          <input
           #firstName="ngModel"
           [(ngModel)]="admin.firstName"
            minlength="3"
            maxlength="10"
            type="text"
            name="firstName"
            matInput
            placeholder="First Name"
            required/>

          @if(firstName.invalid && firstName.touched) {
          <mat-error>First Name is required</mat-error>
          }
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Last Name</mat-label>
          <input
           #lastName="ngModel"
           [(ngModel)]="admin.lastName"
            minlength="3"
            maxlength="14"
            type="text"
            name="lastName"
            matInput
            placeholder="Last Name"
            required/>

            @if(lastName.invalid && lastName.touched) {
              <mat-error>Last Name is required</mat-error>
              } 
        </mat-form-field>
      </div>

      <div class="input-fields">
        <mat-form-field appearance="outline">
          <mat-label>E-Mail</mat-label>
          <input
            name="email"
            matInput
            #email="ngModel"
            [(ngModel)]="admin.email"
            placeholder="E-Mail"
            pattern="[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}"
            (input)="onEmailInput()"
            required/>

            @if(signupForm.invalid && email.touched) {
              <mat-error>Email is required</mat-error>
              } 

              <!-- @if(emailExists) {
                <mat-error>{{emailExists}}</mat-error>
                }  -->

            @if(emailExists) {
              <span style="color: red;">{{emailExists}}</span>
            }
            </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input
            maxlength="14"
            name="password"
            matInput
            #password="ngModel"
            [(ngModel)]="admin.password"
            placeholder="Password"
            required/>

            @if(password.invalid && password.touched) {
              <mat-error>Password is required</mat-error>
            }

            @if(password.dirty) {
              <mat-error>Wrong pattern for password</mat-error>
            }

            @if(weakPassword) {
              <mat-error>{{weakPassword}}</mat-error>
            }
            
        </mat-form-field>
      </div>
      <button mat-flat-button color="primary" type="submit">Sign Up</button>
    </form>
  
    <div class="login-container">
      <h3 class="mb-0">Already a User?</h3>
      <a [routerLink]="['/']">Log in</a>
    </div>
  </mat-card>