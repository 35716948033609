<div class="dialog-container">
  @if(loading) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  }

  <h2 mat-dialog-title>Add User</h2>
  <mat-dialog-content>
    <form #userForm="ngForm" (ngSubmit)="saveUser(userForm)">
      <div>
        <mat-form-field class="mr-16" appearance="outline">
          <mat-label>First Name</mat-label>
          <input
            [disabled]="loading"
            minlength="3"
            maxlength="10"
            type="text"
            #firstName="ngModel"
            name="firstName"
            [(ngModel)]="user.firstName"
            matInput
            placeholder="First Name"
            required/>

          @if(firstName.invalid && firstName.touched) {
          <mat-error>First Name is required</mat-error>
          }
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Last Name</mat-label>
          <input
            [disabled]="loading"
            minlength="3"
            maxlength="14"
            type="text"
            #lastName="ngModel"
            name="lastName"
            [(ngModel)]="user.lastName"
            matInput
            placeholder="Last Name"
            required/>

            @if(lastName.invalid && lastName.touched) {
              <mat-error>Last Name is required</mat-error>
              }
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="mr-16 full-width" appearance="outline">
          <mat-label>E-Mail</mat-label>
          <input
            [disabled]="loading"
            #email="ngModel"
            name="email"
            [(ngModel)]="user.email"
            matInput
            placeholder="E-Mail"
            pattern="[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}"
            required/>

            @if(email.invalid && email.touched) {
              <mat-error>Email is required</mat-error>
              }
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="mr-16 full-width" appearance="outline">
          <mat-label>Birth Date</mat-label>
          <input
            [disabled]="loading"
            #birthDate="ngModel"
            name="birthDate"
            [(ngModel)]="birthDate"
            matInput
            placeholder="Birthdate"
            [matDatepicker]="datepicker"
            required/>
          <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
          <mat-datepicker #datepicker></mat-datepicker>

          @if(birthDate.invalid && birthDate.touched) {
            <mat-error>Birth Date is required</mat-error>
            }
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Street + House No.</mat-label>
          <input
            [disabled]="loading"
            pattern="^[a-zA-Z0-9\s\-\.]+ \d{1,3}$"
            #street="ngModel"
            name="street"
            [(ngModel)]="user.street"
            matInput
            placeholder="Placeholder"
            required/>

            @if(street.invalid && street.touched) {
              <mat-error>Street is required</mat-error>
              }
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="mr-16" appearance="outline">
          <mat-label>Zip code</mat-label>
          <input
            [disabled]="loading"
            minlength="5"
            maxlength="9"
            #zipCode="ngModel"
            name="zipCode"
            [(ngModel)]="user.zipCode"
            matInput
            placeholder="Placeholder"
            required/>

            @if(zipCode.invalid && zipCode.touched) {
              <mat-error>Zip Code is required</mat-error>
              }
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>City</mat-label>
          <input
            [disabled]="loading"
            minlength="3"
            maxlength="32"
            #city="ngModel"
            name="city"
            [(ngModel)]="user.city"
            matInput
            placeholder="Placeholder"
            required/>

            @if(city.invalid && city.touched) {
              <mat-error>City is required</mat-error>
              }
        </mat-form-field>
      </div>
      <mat-dialog-actions>
        <button [disabled]="loading" mat-button type="button" (click)="dialogRef.close()">Cancel</button>
        <button
          [disabled]="loading || !userForm.form.valid"
          mat-button
          type="submit">Save</button>
      </mat-dialog-actions>
    </form>
  </mat-dialog-content>
</div>
