<ng-container *ngIf="!isLoginPage(); else loginPageTemplate">
<mat-drawer-container class="mat-drawer-container" autosize>
  <mat-drawer #drawer class="sidenav" [opened]="true" mode="side">
    <div class="logo-contaiener"><img src="./../assets/image/logo.png"></div>
    <ul class="menu-items">
      <li [routerLink]="['/dashboard']"><mat-icon>dashboard</mat-icon><b>Dashboard</b></li>
      <li [routerLink]="['/user']"><mat-icon>person</mat-icon><b>User</b></li>
    </ul>
    <div class="legals">
      <a [routerLink]="['/imprint']">Imprint</a>
      <a [routerLink]="['/privacy']">Privacy Policy</a>
    </div>
  </mat-drawer>

  <div class="example-sidenav-content">
    <mat-toolbar color="primary">
      <mat-toolbar-row>
        <mat-icon class="menu-icon" (click)="drawer.toggle()">menu</mat-icon>
        <span>Simple CRM</span>
      </mat-toolbar-row>
    </mat-toolbar>
    <div class="router-container">
      <router-outlet></router-outlet>
    </div>
  </div>

</mat-drawer-container>
</ng-container>

<ng-template #loginPageTemplate>
  <router-outlet></router-outlet>
</ng-template>