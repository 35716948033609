<div class="filter-container mb-32">
  <mat-form-field class="full-width mb-32">     
    <input matInput (keyup)="applyFilter($event)" 
    placeholder="Search Name or City" autocomplete="off">
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>

  <section>
    <div class="example-button-row button-container">
      <button (click)="sortName()" mat-flat-button color="primary">First Name</button>
      <button mat-flat-button color="primary">Revenue Ascending</button>
      <button mat-flat-button color="primary">Revenue Descending</button>
    </div>
  </section>
</div>


<mat-card>
  <table class="full-width user-table">
    <tr>
      <th>Name</th>
      <th>E-mail</th>
      <th>City</th>
    </tr>
        <tr *ngFor="let user of userService.filteredUsers" [routerLink]="['/user/' + user.id]">
          <td>{{user.firstName}} {{user.lastName}}</td>
          <td>{{user.email}}</td>
          <td>{{user.city}}</td>
        </tr>
  </table>
</mat-card>

@if (this.userService.filteredUsers.length === 0) {
  <div class="no-users-message">
    <h3>No Users in the List</h3>
  </div>
}

<button (click)="openDialog()" class="add-user-button" mat-fab color="primary" matTooltip="add user">
    <mat-icon>add</mat-icon>
  </button>


