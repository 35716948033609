<div class="dialog-container">
    @if(loading) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }
  
  <h2 mat-dialog-title>Edit Address</h2>
  <mat-dialog-content class="pt-20">
      <div>
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Street + House No.</mat-label>
          <input [disabled]="loading" [(ngModel)]="user.street" matInput placeholder="Placeholder" >
          <!-- <mat-hint>Please enter a Name</mat-hint> -->
        </mat-form-field>
      </div>
      <div>
          <mat-form-field class="mr-16" appearance="outline">
            <mat-label>Zip code</mat-label>
            <input [disabled]="loading" [(ngModel)]="user.zipCode" matInput placeholder="Placeholder" >
            <!-- <mat-hint>Please enter a Name</mat-hint> -->
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>City</mat-label>
            <input [disabled]="loading" [(ngModel)]="user.city" matInput placeholder="Placeholder" >
            <!-- <mat-hint>Please enter a Name</mat-hint> -->
          </mat-form-field>
        </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button [disabled]="loading" mat-button (click)="dialogRef.close();">Cancel</button>
    <button [disabled]="loading" mat-button (click)="saveUser()" cdkFocusInitial>Save</button>
  </mat-dialog-actions>
  </div>
